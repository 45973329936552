import Vue from 'vue'
 
// import directory from '../views/components/directory' 
 


Vue.component('mbs-company-select',()=>
    import('../views/components/companySelect.vue'))
Vue.component('mbs-live-till',()=>
    import('../views/components/liveTill.vue'))
Vue.component('mbs-live-stock-take',()=>
    import('../views/components/liveStockTake.vue'))
Vue.component('mbs-named-computer',()=>
    import('../views/components/namedComputer.vue'))
Vue.component('mbs-order-option',()=>
    import('../views/components/orderOption.vue'))
Vue.component('mbs-cashup-manage-expenses',()=>
    import('../views/components/cashupManageExpenses.vue'))
    
//-----SEARCH SELECT
Vue.component('mbs-search-select-items',()=>
    import('../views/components/search_select_items/searchSelectItems.vue'))
Vue.component('mbs-warehouse-search-bar',()=>
    import('../views/components/search_select_items/warehouseSearchBar.vue'))
Vue.component('mbs-click-select-items',()=>
    import('../views/components/search_select_items/clickSelectItems.vue'))
//SELECTED ITEMS
Vue.component('mbs-selected-items',()=>
    import('../views/components/selected_items/selectedItems.vue'))
Vue.component('mbs-sales-selected-items',()=>
    import('../views/components/selected_items/salesSelectedItems.vue'))
Vue.component('mbs-warehouse-selected-items',()=>
    import('../views/components/selected_items/warehouseSelectedItems.vue'))
Vue.component('mbs-stock-taking-selected-items',()=>
    import('../views/components/selected_items/stockTakingSelectedItems.vue'))
Vue.component('mbs-adjust-stock-selected-items',()=>
    import('../views/components/selected_items/adjustStockSelectedItems.vue'))

//DOCUMENT SHOW
Vue.component('mbs-doc-show-sales-invoice',()=>
    import('../views/components/show_documents/doc_show_sales_invoice.vue'))
Vue.component('mbs-doc-show-sales-payment',()=>
    import('../views/components/show_documents/doc_show_sales_payment.vue'))
Vue.component('mbs-dialog-doc-show',()=>
    import('../views/components/show_documents/dialog_doc_show.vue'))

Vue.component('mbs-receipt-finder',()=>
    import('../views/components/receiptFinder.vue'))
    
//-----PRINTING TEMPLATES
Vue.component('mbs-invoice-temp1',()=>
    import('../views/components/print_templates/invoice_temp1.vue'))
Vue.component('mbs-invoice-statement-temp1',()=>
    import('../views/components/print_templates/invoice_statement_temp1.vue'))
Vue.component('mbs-receipt-temp1',()=>
    import('../views/components/print_templates/receipt_temp1.vue'))
Vue.component('mbs-payment-receipt-temp1',()=>
    import('../views/components/print_templates/payment_receipt_temp1.vue'))
Vue.component('mbs-profit-and-loss-temp1',()=>
    import('../views/components/print_templates/profit_and_loss_temp1.vue'))
Vue.component('mbs-day-shift-temp1',()=>
    import('../views/components/print_templates/day_shift_temp1.vue'))
Vue.component('mbs-day-shift-temp2',()=>
    import('../views/components/print_templates/day_shift_temp2.vue'))
Vue.component('mbs-day-shift-temp3',()=>
    import('../views/components/print_templates/day_shift_temp3.vue'))
Vue.component('mbs-stock-order-temp1',()=>
    import('../views/components/print_templates/stock_order_temp1.vue'))
Vue.component('mbs-epson-cashup-temp1',()=>
    import('../views/components/print_templates/epson_cashup_temp1.vue'))
Vue.component('mbs-epson-day-shift-temp1',()=>
    import('../views/components/print_templates/epson_day_shift_temp1.vue'))
Vue.component('mbs-sales-temp1',()=>
    import('../views/components/print_templates/epson_sales_temp1.vue'))
Vue.component('mbs-pdf-sales-temp1',()=>
    import('../views/components/print_templates/pdf_sales_temp1.vue'))
Vue.component('mbs-epson-display-temp1',()=>
    import('../views/components/print_templates/epson_display_temp1.vue'))
Vue.component('mbs-epson-sales-items-temp1',()=>
    import('../views/components/print_templates/epson_sales_items_temp1.vue'))


Vue.component('mbs-day-shift-sales-report',()=>
    import('../views/components/dayShiftSalesReport.vue'))