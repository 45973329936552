<template>
    <div>   
        <v-divider></v-divider>
        <mbs-page-tab 
            :items="MY_LINKS"/> 
        <v-divider></v-divider>
        <v-divider></v-divider>
        <v-container grid-list-xl> 
            <v-layout align-center="" justify-center="">
                <v-col sm="12" cols="12">
                    <br>
                    <v-card > 
                            <!-- add_item_title="PAY EXPENSE"
                            :add_item_action="{
                                action_do:'Pay',
                                action_doing:'Paying'
                            }" -->
                        <mbs-item-manager
                            :filters="FILTERS"
                            :table_tabs="TABLE_TABS"
                            :add_vertical_lines="true"
                            :row_click="true"
                            :table_title="'Manage Expenses'"
                            :table_title_icon="'mdi-clipboard-text-clock'"
                            :top_label="false"
                            :filter_date="true"
                            :filter_date_value="'expense_date'"
                            :filter_default_days="'90'"
                            :name_items="NAME_ITEMS"
                            :name_item="NAME_ITEM"
                            :value_items="VALUE_ITEMS"
                            :value_item="VALUE_ITEM"
                            :field_items="DATA.FIELDS.expenses"
                            :header_items="HEADER_ITEMS" 
                            :data_items="ExpensesData" 
                            :item_data_include="{
                                order_type:DATA.ITEMS.EXPENSES.value
                            }"   
                            :is_joined="true"  
                            :path_item="PATH_ITEM"  
                            :select_items="SELECT_ITEMS"  
                            :total_item_values="TOTAL_ITEM_VALUES"  

                            :table_actions="table_actions" 
                            @SELECT_TABLE_ACTION="TABLE_ACTION"
                        />
                    </v-card>
                    <br><br>
                </v-col>  
            </v-layout>
        </v-container>  
    </div> 
</template>

<script>
    import DATA from '../../../../plugins/DATA'
    import {mapState} from "vuex"
    let PAGE_NAME = DATA.ITEMS.EXPENSES.values    
    export default {
        name:PAGE_NAME, 
        data() {
            return {
                PAGE_LOAD:false,  
                PAGE_CURRENT:1,
                PAGE_SIZE:10,

                NAME_ITEMS:DATA.ITEMS.EXPENSES.names,
                NAME_ITEM:DATA.ITEMS.EXPENSES.name,
                VALUE_ITEMS:DATA.ITEMS.EXPENSES.values,
                VALUE_ITEM:DATA.ITEMS.EXPENSES.value,  
                DATA:DATA,
 
                table_actions:[
                    {type:"btn",action:"add_item",color:"secondary", outlined:false,  text:"Add / Record Expense"}, 
                    {type:"btn",action:"manage_expenses",color:"secondary",  text:"Manage Expense Accounts"}, 
                    // {type:"btn",action:"return_stock",color:"secondary",  text:"Return Stock"}, 
                    // {type:"btn",action:"transfer_stock",color:"secondary",  text:"Transfer Stock"}, 
                    {type:"action",icon:"mdi-microsoft-excel",action:"export_excel",color:"secondary", outlined:false,  text:"Export to Excel"},  
                ],
                TOTAL_ITEM_VALUES:[ 
                    // {name:'Closing Stock (QTY)',value:'number_quantity',show_value:"number_quantity"},
                    {name:'Total Amount',value:'amount',show_value:'amount_',money:true},
                ],
            }
        },
        created() {
            try {
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            try {
                this.MBS.actions.header({
                    show:true,
                    type:'subToolbar', 
                    images:null,
                    color:'black',
                    height:"300",
                    opacity:'0.1'
                })
                this.FEEDBACKS(1000) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            }
            
        },
        computed: {
            route(){
                return this.$route
            },
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            },
            vs_width(){
                let vs = this.vs
                if(!vs){return null}
                return vs.width
            }, 
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            }),
            ...mapState({
                Expenses: state=> state.items[(DATA.ITEMS.EXPENSES.values).toUpperCase()],
                JoinedExpenses: state=> state.join[(DATA.ITEMS.EXPENSES.values).toUpperCase()], 
                StockOutlets: state=> state.items[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()],
                JoinedStockOutlets: state=> state.join[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()], 
                ExpenseCategories: state=> state.items[(DATA.ITEMS.EXPENSE_CATEGORIES.values).toUpperCase()],
                JoinedExpenseCategories: state=> state.join[(DATA.ITEMS.EXPENSE_CATEGORIES.values).toUpperCase()], 
                ExpenseAccounts: state=> state.items[(DATA.ITEMS.EXPENSE_ACCOUNTS.values).toUpperCase()],
                JoinedExpenseAccounts: state=> state.join[(DATA.ITEMS.EXPENSE_ACCOUNTS.values).toUpperCase()], 
                Suppliers: state=> state.items[(DATA.ITEMS.SUPPLIERS.values).toUpperCase()],
                JoinedSuppliers: state=> state.join[(DATA.ITEMS.SUPPLIERS.values).toUpperCase()], 
                PaymentMethods: state=> state.items[(DATA.ITEMS.PAYMENT_METHODS.values).toUpperCase()],
                JoinedPaymentMethods: state=> state.join[(DATA.ITEMS.PAYMENT_METHODS.values).toUpperCase()],
                DefaultPaymentMethod: state=> state.app[(DATA.ITEMS.DEFAULT_PAYMENT_METHODS.values).toUpperCase()],
                DefaultMoneyAccount: state=> state.app[(DATA.ITEMS.DEFAULT_MONEY_ACCOUNTS.values).toUpperCase()], 
                DefaultBillAccount: state=> state.app[(DATA.ITEMS.DEFAULT_BILL_METHODS.values).toUpperCase()], 
                DefaultExpenseCategories: state=> state.app['DEFAULT_EXPENSE_CATEGORIES'],
                DefaultExpenseAccounts: state=> state.app['DEFAULT_EXPENSE_ACCOUNTS'],   
                SelectedCompany: state=> state.items["SELECTED_COMPANY"],  
            }),  
            ExpensesData(){
                let items = this.JoinedExpenses?this.JoinedExpenses:this.Expenses 
                if(!items){return null}
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("created_at_server",false))   
                list.sort(this.MBS.actions.dynamicSort("expense_date",false))    
                return list 
            },
            StockOutletsData(){
                let items = this.JoinedStockOutlets?this.JoinedStockOutlets:this.StockOutlets  
                return items 
            },
            ExpenseAccountsData(){ 
                let items = this.JoinedExpenseAccounts?this.JoinedExpenseAccounts:this.ExpenseAccounts
                return items
            },
            SuppliersData(){
                let items = this.JoinedSuppliers?this.JoinedSuppliers:this.Suppliers
                return items
            }, 
            PaymentMethodsData(){
                let items = []
                let DefaultBillAccount = this.DefaultBillAccount  
                let DefaultPaymentMethod = this.DefaultPaymentMethod 
                let PaymentMethods= this.JoinedPaymentMethods?this.JoinedPaymentMethods:this.PaymentMethods
                if(DefaultBillAccount){
                    items = [...DefaultBillAccount]
                }
                if(DefaultPaymentMethod){
                    items= [
                        ...items,
                        ...DefaultPaymentMethod
                    ]
                } 
                if(PaymentMethods){
                    items= [
                        ...items,
                        ...PaymentMethods
                    ]
                } 
                return items
            },
            AllExpenseAccounts(){
                let defaults = this.DefaultExpenseAccounts
                defaults = this.MBS.actions.JOIN_EXPENSE_ACCOUNTS(defaults)
                let accounts = this.ExpenseAccountsData
                let all = []
                if(defaults){
                    defaults.forEach(category => {
                        all.push({
                            ...category,
                            default:true
                        }) 
                    });
                }
                if(accounts){
                    all=[
                        ...all,
                        ...accounts
                    ]
                } 
                
                return all
            },
            AllExpenseCategories(){
                let defaults = this.DefaultExpenseCategories
                let categories = this.ExpenseCategoriesData
                let all = []
                if(defaults){
                    defaults.forEach(category => {
                        all.push({
                            ...category,
                            default:true
                        }) 
                    });
                }
                if(categories){
                    all=[
                        ...all,
                        ...categories
                    ]
                } 
                return all
            },


            MY_LINKS(){  
                let thisLinks = this.MBS.actions.FIND_SUB_LINKS("office",1,true) 
                return thisLinks
            }, 
            PATH_ITEM(){  
                let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.expenses,true) 
                return path
            }, 
            FILTERS(){ 
                return [   
                    {
                        type:"autocomplete",value:"payment_method_code",prepend_inner_icon:'mdi-account-cash',clearable:true,filled:false,multiple:true,
                        chips:true,deletable_chips:true,label:"Payment",
                        select:{
                            name:'PaymentMethods',
                            value:'code',
                            text:'name'
                        }, 
                        required:false,sm:12,md:6,step_name:"Name"
                    },   
                    {
                        type:"autocomplete",value:"expense_account_code",barcode:true,prepend_inner_icon:'mdi-cash-sync',clearable:true,filled:false,multiple:true,
                        chips:true,deletable_chips:true,label:"Account",
                        select:{
                            name:'ExpenseAccounts',
                            value:'code',
                            text:'name'
                        }, 
                        required:false,sm:12,md:6,step_name:"Name"
                    },     
                    {
                        type:"autocomplete",value:"supplier_code",barcode:true,prepend_inner_icon:'mdi-human-dolly',clearable:true,filled:false,multiple:true,
                        chips:true,deletable_chips:true,label:"Supplier",
                        select:{
                            name:'Suppliers',
                            value:'code',
                            text:'name'
                        }, 
                        required:false,sm:12,md:12,step_name:"Name"
                    },     
                ]
            }, 
            TABLE_TABS(){
                const outlets = this.StockOutletsData 
                if (this.MBS.actions.SIZE(outlets)<=1) {
                    return null
                }
                
                let taps = [
                    {
                        name:"All",
                        show_if:(this_,item)=>true,
                        indicator:{
                            color:'primary',
                            value:(this_,MBS,data)=>{  
                                return MBS.SIZE(data)
                            }
                        }
                    },
                ]
                if (outlets) {
                    outlets.forEach(outlet => {
                        taps.push({
                            name:outlet.name+" Expenses",
                            show_if:(this_,item)=>item.outlet_code==outlet.code,
                            indicator:{
                                color:'primary',
                                value:(this_,MBS,data)=>{  
                                    return MBS.SIZE(data)
                                }
                            }
                        })
                    });
                }
                return  taps
            },
            HEADER_ITEMS(){
                return [ 
                    {id:1,name:"General",title:true}, 
                    {id:0,name:"Number",value:'no',show:false},   
                    {id:0,name:"code",align:"center",show:true},     
                    {id:0,name:"Exp. date",value:'expense_date',show:true},   
                    {id:0,name:"Supplier",value:"supplier_name",show:true},     
                    {id:0,name:"Account",value:"expense_account_name",show:true},     
                    {id:0,name:"Payment",value:"payment_method_name",align:'center',show:true},     
                    {id:0,name:"Status",value:"payment_status",show:true},     
                    {id:0,name:"description",value:"description",show:true},         
                    {id:0,name:"amount",value:"amount",align:"right",show:true},     
                    {id:0,name:"uploaded at",value:'created_at_',show:false},     
                    {id:0,name:"update at",value:'updated_at_',show:false},   
                    {id:0,name:"action",align:"right",show:true,item:[ 
                        {name:"Edit Expense Account",action:"edit_item",icon:"edit"}, 
                        {name:"Archive Expense Account",action:"archive_item",icon:"mdi-delete"}, 
                    ]}, 
                ]
            }, 
            AllExpense(){ 
                return this.$store.getters.getExpenses
            },   
            
            SELECT_ITEMS(){ 
                let ExpenseCategories = this.AllExpenseCategories  
                let ExpenseAccounts = this.AllExpenseAccounts  
                let Suppliers = this.SuppliersData
                let PaymentMethods = this.PaymentMethodsData   
                let StockOutlets = this.StockOutletsData   
 
                return {
                    ExpenseAccounts:ExpenseAccounts,
                    Suppliers:Suppliers,
                    PaymentMethods:PaymentMethods,
                    ExpenseCategories:ExpenseCategories,
                    StockOutlets:StockOutlets,
                }
            },
        },
        methods: { 
            TABLE_ACTION(action,item){
                try {
                    let route = this.route
                    let fullPath = route?route.fullPath:null 
                    if (action == "manage_expenses") {
                        this.MBS.actions.go(this.MBS.actions.COMPANY_LINK("/office/accounts/expenses",true))
                    }  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
             
            //---------------[MAIN FUNCTIONS]------------ 
            DIALOG_YES(action){ 
                try {
                    if (action.code) {
                        if (action.code ===PAGE_NAME+'=ADD-ITEM') {
                            if (action.YES) {
                                if (this.input.check) { 
                                    this.input.check = false 
                                    setTimeout(() => {
                                        this.input.loading = true 
                                        this.$store.dispatch("fi_add",{
                                            action:this.VALUE_ITEM,
                                            path:this.PATH_ITEM,
                                            data:action.data,
                                            us:this.us
                                        })
                                    }, ACTION_TIME);  
                                }   
                            } else {
                                
                            }
                        }  
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },  
            FEEDBACKS(time){ 
                try {
                    if (!time) { time=400 }
                    setTimeout(()=>{
                        let us = this.loggedUser 
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses

                        //Check process 
                        if (ps) {
                            let gettingItems = ps.find(process=>{
                                return process.name == 'getting/'+this.VALUE_ITEMS
                            })
                            
                             
                             
                        }else{
                            this.PAGE_LOAD =true
                        } 
                    },time)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FEEDBACKS',
                        page:PAGE_NAME, 
                    })  
                } 
            },
            PAGE_PERMISSION(){
                try {
                    let us
                    if (!us) {
                        this.MBS.actions.go("/")
                    }  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'PAGE_PERMISSION',
                        page:PAGE_NAME, 
                    })
                }
            }, 
        },
        watch: { 
            us(v){
                this.PAGE_PERMISSION()
            },
            loading(value){
                this.FEEDBACKS(1)
            },
            processes(value){
                this.FEEDBACKS(1)
            },
            responses(value){
                this.FEEDBACKS(1) 
            } 
        },
    }
</script>
 
